/* eslint-disable react/style-prop-object */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
/* TellUsAboutYou.jsx */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import './TellUsAboutYou.css';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const TellUsAboutYou = () => {
  const location = useLocation();
  const { token } = location.state || {};
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');// New state for phone number

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await Axios.post(`${baseUrl}/Register/complete-account-info`, { name, dob, phone }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        navigate('/dashboard', { state: {token} });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);


  return (
    <div className="tell-us-about-you">
      <div className="logo-container">
        <img src={logo} alt="Seda Logo" className="logo" />
      </div>
      <form className="info-form" onSubmit={handleSubmit}>
        <h2>Tell us about you</h2>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
        <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} placeholder="Date of Birth" required />
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" required />  {/* New phone input field */}

        <p style={{ marginTop: '20px' }}>
  By clicking "Continue", you agree to our <a href="/Seda_Terms_of_Service_V1.pdf" target="_blank" rel="noopener noreferrer">Terms and Privacy Policy</a>
        </p>

        <button type="submit" className="button">Continue</button>
      </form>
      <div className="gradient-bar"></div>
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

    </div>
  );
};
