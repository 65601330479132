/* eslint-disable object-curly-spacing */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Dashboard.css';
import { v4 as uuidv4 } from 'uuid';
import userIconImage from '../../components/User_Icon.png'; // Adjust the path accordingly
import sedaLogo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png'; // Adjust the path accordingly
import cursorLogo from '../../components/cursor.png'; // Adjust the path accordingly
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const Dashboard = (props) => {
  const Base_url = process.env.REACT_APP_BASE_URL;

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [role, setRole] = useState('');
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState('');
  const [deletePassword, setDeletePassword] = useState('');

  const { state } = useLocation();
  const { token, Username } = state; // Read values passed on state
  Axios.defaults.withCredentials = true;
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get(Base_url + '/login').then((response) => {
      if (response.data.loggedIn == true) {
        console.log(response.data.loggedIn);
      } else {
        console.log(response.data.loggedIn);
      }
    });
  }, []);

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSignOut = () => {
    // Handle sign out logic here
    navigate('/login');
  };

  const handleDeleteAccount = async () => {
    try {
      await Axios.post(`${Base_url}/account-settings/delete`, { email: deleteEmail, password: deletePassword });
      alert('Account deleted successfully');
      navigate('/');
    } catch (error) {
      alert(`Error deleting account: ${error.response?.data?.message || error.message}`);
      console.error(error);
    }
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleDeleteClick = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleModalClick = (e) => {
    if (e.target.className === 'settings-modal') {
      setShowSettingsModal(false);
    }
  };

  const handleConfirmModalClick = (e) => {
    if (e.target.className === 'confirm-delete-modal') {
      setShowConfirmDeleteModal(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await Axios.post(`${Base_url}/stripe/cancel-subscription`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.success) {
        alert('Subscription cancelled successfully');
        // Update the UI to reflect the cancelled subscription
      } else {
        alert(response.data.error || 'Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      if (error.response && error.response.status === 404) {
        alert(error.response.data.error || 'Resource not found');
      } else {
        alert(error.response?.data?.error || 'An error occurred while cancelling the subscription');
      }
    }
  };
  return (
    <div className="dashboard-container">
      <div className="content">
        <div className="sidebar">
          <div className="logo">
            <img src={sedaLogo} alt="Seda Logo" className="seda-logo" />
          </div>
          <div className="user-info" onClick={toggleMenu}>
            <img src={userIconImage} alt="User Icon" className="user-icon" /> {/* Use the UserIcon image */}
            <h3>{Username}</h3>
            {showMenu && (
              <div className="menu">
                <button onClick={handleSettingsClick}>Settings</button>
                <button onClick={handleSignOut}>Sign Out</button>
              </div>
            )}
          </div>
        </div>
        <div className="main-content-dashboard">
          <div className="center-content">
            <img src={cursorLogo} alt="Cursor Logo" className="cursor-logo" />
            <h1>Welcome!</h1>
            <p>To get started, Download Seda for your Operating system.<br />Once you’ve downloaded Seda, launch the app and you’re ready to go!</p>
            <button className="launch-button">Launch Seda</button>
            <a href="/Seda_1.1.1.pkg" className="download-button" download>
              Download Seda For MacOS
            </a>
            <a href="https://docs.google.com/document/d/1VD96Y5dZ9amu5vCHcOAWgay8UCS89jFi1a_z47GyKCs/edit?usp=sharing" className="download-button" target="_blank" rel="noopener noreferrer">
              View Beta User Manual
            </a>
          </div>
        </div>
      </div>
      <div className="gradient-bar"></div>
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

      {showSettingsModal && (
        <div className="settings-modal" onClick={handleModalClick}>
          <div className="settings-modal-content">
            <button onClick={() => setShowSettingsModal(false)} className="close-button">X</button>
            <h2>Settings</h2>
            <button onClick={handleCancelSubscription} className="cancel-subscription-button">Cancel Subscription</button>
            <button onClick={handleDeleteClick} className="delete-account-button">Delete Account</button>
          </div>
        </div>
      )}

      {showConfirmDeleteModal && (
        <div className="confirm-delete-modal" onClick={handleConfirmModalClick}>
          <div className="confirm-delete-modal-content">
            <h2>Confirm Delete Account</h2>
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={deleteEmail}
              onChange={(e) => setDeleteEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Enter your password"
              value={deletePassword}
              onChange={(e) => setDeletePassword(e.target.value)}
              required
            />
            <button onClick={handleDeleteAccount} className="confirm-delete-button">Yes, Delete</button>
            <button onClick={() => setShowConfirmDeleteModal(false)} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};
